<script>
    import Spinner from "$lib/Spinner.svelte";

    export let color = "blue";
    export let align = "center";
    export let disabled = false;
    export let loading = false;
    export let selected = false;
    export let small = false;
    export let height = null;
    export let width = null;
    $: disabledOrLoading = disabled || loading;

    let style;
    $: {
        style = null;
        if (height) {
            style = `height: ${height}px;`;
        }
        if (width) {
            if (!style) style = "";
            style += `width: ${width}px;`;
        }
    }
</script>

<button on:click|stopPropagation|preventDefault
        type="button"
        class:disabled
        class:loading
        class:selected
        class:small
        disabled={disabledOrLoading}
        class:red="{color === 'red'}"
        class:orange="{color === 'orange'}"
        class:green="{color === 'green'}"
        class:teal="{color === 'teal'}"
        class:blue="{color === 'blue'}"
        class:blue2="{color === 'blue2'}"
        class:purple="{color === 'purple'}"
        class:pink="{color === 'pink'}"
        class:secondary="{color === 'secondary'}"
        class:dark="{color === 'dark'}"
        class:transparent="{color === 'transparent'}"
        class:link="{color === 'link'}"
        class:outline="{color === 'outline'}"
        class:subtleOutline="{color === 'subtleOutline'}"
        class:alignLeft="{align === 'left'}"
        class:alignCenter="{align === 'center'}"
        class:alignRight="{align === 'right'}"
        {style}
        {...$$restProps}>
    {#if loading}
        <Spinner/>
    {/if}
    <slot></slot>
</button>

<style lang="scss">
  button {
    user-select: none;
    -webkit-user-select: none;
    width: 100%;
    border: none;
    border-radius: 10px;

    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    text-align: center;
    color: #FFFFFF;

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 10px;

    opacity: 1;

    &.small {
      width: auto;
      flex: 0;
    }

    &.alignLeft {
      justify-content: flex-start;
      text-align: left;
    }

    &.alignCenter {
      justify-content: center;
      text-align: center;
    }

    &.alignRight {
      justify-content: flex-end;
      text-align: right;
    }


    &.transparent {
      background: none;
      padding: 0;
      font-weight: normal;
    }

    &.link {
      display: inline !important;
      background: none;
      border: none;
      font-weight: normal;
      font-size: 14px;
      text-decoration: underline;
      padding: 0;
      gap: 0;
    }

    &.red {
      background: var(--color-background-red);
      color: var(--color-text-white);
      border: 2px solid var(--color-background-red);

      &.selected {
        background: var(--color-background-dark-red);
      }
    }

    &.orange {
      background: var(--color-background-orange);
      color: var(--color-text-white);
      border: 2px solid var(--color-background-orange);

      &.selected {
        background: var(--color-background-dark-orange);
      }
    }

    &.green {
      background: var(--color-background-green);
      color: var(--color-text-white);
      border: 2px solid var(--color-background-green);

      &.selected {
        background: var(--color-background-dark-green);
      }
    }

    &.teal {
      background: var(--color-background-teal);
      color: var(--color-text-white);
      border: 2px solid var(--color-background-teal);

      &.selected {
        background: var(--color-background-dark-teal);
      }
    }

    &.blue {
      background: var(--color-background-blue);
      color: var(--color-text-white);
      border: 2px solid var(--color-background-blue);

      &.selected {
        background: var(--color-background-dark-blue);
      }
    }

    &.blue2 {
      background: var(--color-background-blue2);
      color: var(--color-text-white);
      border: 2px solid var(--color-background-blue2);

      &.selected {
        background: var(--color-background-dark-blue2);
      }
    }

    &.purple {
      background: var(--color-background-purple);
      color: var(--color-text-white);
      border: 2px solid var(--color-background-purple);

      &.selected {
        background: var(--color-background-dark-purple);
      }
    }

    &.pink {
      background: var(--color-background-pink);
      color: var(--color-text-white);
      border: 2px solid var(--color-background-pink);

      &.selected {
        background: var(--color-background-dark-pink);
      }
    }

    &.secondary {
      background: var(--color-background-secondary-light);
      color: var(--color-text-white);
      border: 2px solid var(--color-background-secondary-light);

      &.selected {
        background: var(--color-background-secondary);
      }
    }

    &.dark {
      background: #444D7C;
      color: var(--color-text-white);
      border: 2px solid #444D7C;

      &.selected {
        background: #343D6C;
      }
    }

    &.outline {
      background: none;
      color: var(--color-text-white);
      border: 2px solid #444D7C;
      border-radius: 8px;

      &.selected {
        background: var(--color-background-secondary);
      }
    }

    &.subtleOutline {
      height: 36px;
      background: none;
      color: #7756FF;
      text-align: center;
      font-feature-settings: 'clig' off, 'liga' off;
      font-family: Poppins, sans-serif;
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: 23.5px; /* 167.857% */
      text-transform: uppercase;
      border-radius: 8px;
      border: 2px solid #565F94;
      padding: 2px 0 0 0;
    }

    &.disabled {
      border: none !important;
      background: var(--color-background-secondary) !important;
      cursor: not-allowed;
      opacity: 0.33;
    }

    &.loading {
      cursor: wait;
      border: none !important;
      background: var(--color-background-secondary) !important;
      opacity: 0.66;
    }

    &:active {
      animation: scale 0.1s;

      &.disabled {
        animation: none;
      }

      &.loading {
        animation: none;
      }

      &.alignLeft {
        transform-origin: left;
      }

      &.alignCenter {
        transform-origin: center;
      }

      &.alignRight {
        transform-origin: right;
      }
    }

    @keyframes scale {
      0% {
        transform: scale(1);
      }
      100% {
        transform: scale(1.05);
      }
    }
  }
</style>